<template>
  <div id="new-lo-title-create">
    <v-overlay :value="ProgressLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>{{ PageDescription }}</h4>
      </v-card-subtitle>

      <v-card-text>
        <v-row wrap>
          <v-col align="center" cols="12" md="12">
            <v-btn
              large
              @click.prevent="backPage"
              color="#EDBE38"
              elevation="30"
              shaped
              class="font-size-h6 fsize-3 mr-3 my-3 white--text"
            >
              <v-icon dark>mdi-arrow-left-circle</v-icon>&nbsp; Back
            </v-btn>
            <v-btn
              large
              @click.prevent="refreshPageData"
              color="#EDBE38"
              elevation="30"
              shaped
              class="font-size-h6 mr-3 my-3 white--text"
            >
              <v-icon dark>mdi-refresh</v-icon>&nbsp; Refresh
            </v-btn>
          </v-col>
        </v-row>
        <br />
        <br />
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container v-if="StartupLoadingFlag">
          <v-row wrap>
            <v-col cols="12" md="4" v-for="n in 6" :key="n">
              <v-skeleton-loader
                v-bind="attrs"
                type="paragraph"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-container>
        <v-form ref="form2" v-model="valid" lazy-validation>
          <v-container v-if="!StartupLoadingFlag">
            <v-card elevation="3" outlined>
              <v-card-title>
                <span> Sponsor LO details &nbsp; </span>
              </v-card-title>
              <v-container>
                <v-row wrap>
                  <v-col cols="12" sm="6" md="3">
                    <label
                      ><h6>
                        <span class="text-danger">*</span> President Details
                      </h6></label
                    >
                    <v-img
                      :src="Member.MemberImagePath"
                      :alt="Member.MemberName"
                      lazy-src="/no-image-available.png"
                      max-width="100"
                      max-height="100"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                    <br />
                    <h4>{{ Member.MemberName }}</h4>
                    <h4>{{ Member.MembershipId }}</h4>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <label
                      ><h6><span class="text-danger">*</span> LO</h6></label
                    >
                    <h4>{{ Member.LomName }}</h4>
                    <h4>{{ Member.ZoneName }}</h4>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <label
                      ><h6>
                        <span class="text-danger">*</span> Mobile Number
                      </h6></label
                    >
                    <v-text-field
                      v-model="Member.PrimaryMobile"
                      :rules="MobileNoRules"
                      :counter="10"
                      v-mask="'##########'"
                      type="number"
                      hint="Enter your mobile number"
                      dense
                      outlined
                    ></v-text-field>
                    <!-- <h4>{{ Member.MobileNo }}</h4> -->
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <label
                      ><h6>
                        <span class="text-danger">*</span> Email Id
                      </h6></label
                    >
                    <v-text-field
                      v-model="Member.EmailId"
                      :rules="EmailIdRules"
                      type="email"
                      hint="Enter your email id"
                      dense
                      outlined
                    ></v-text-field>
                    <!-- <h4>{{ Member.EmailId }}</h4> -->
                  </v-col>
                </v-row>
              </v-container>
            </v-card>

            <br /><br />

            <v-card elevation="3" outlined>
              <v-card-title>
                <span> Location of new LO &nbsp; </span>
              </v-card-title>
              <v-container>
                <v-row wrap>
                  <v-col cols="12" sm="6" md="4">
                    <label>
                      <h6><span class="text-danger">*</span> State</h6>
                    </label>
                    <v-autocomplete
                      @change="getDistrictCodeOptions"
                      :reduce="(option) => option.value"
                      :loading="StateCodeOptionsLoading"
                      :items="StateCodeOptions"
                      :rules="StateCodeRules"
                      v-model="StateCode"
                      required
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <label>
                      <h6><span class="text-danger">*</span> District</h6>
                    </label>
                    <v-autocomplete
                      @change="getCityCodeOptions"
                      :reduce="(option) => option.value"
                      :loading="DistrictCodeOptionsLoading"
                      :items="DistrictCodeOptions"
                      :rules="DistrictCodeRules"
                      v-model="DistrictCode"
                      required
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <label>
                      <h6>
                        <span class="text-danger">*</span> City
                        <small
                          >(Note – City will the prefix all the title
                          application)</small
                        >
                      </h6>
                    </label>
                    <v-autocomplete
                      @change="generateSelectedLoName"
                      :reduce="(option) => option.value"
                      :loading="CityCodeOptionsLoading"
                      :items="CityCodeOptions"
                      :rules="CityCodeRules"
                      v-model="CityCode"
                      dense
                      clearable
                      required
                      outlined
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <h6>
                      <span class="text-danger">*</span> Signed LGB Resolution
                    </h6>
                    <v-file-input
                      :clearable="false"
                      truncate-length="25"
                      accept="application/pdf"
                      placeholder="Upload pdf"
                      v-model="LGBResolution"
                      prepend-icon="mdi-file-pdf"
                    >
                      <template v-slot:append-outer>
                        <v-icon color="red" @click="LGBResolution = {}">
                          mdi-delete
                        </v-icon>
                      </template>
                    </v-file-input>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <label>
                      <h6>
                        Title Name Extension
                        <small
                          >(Please don’t enter JCI and City , it will be
                          automatically filled)</small
                        >
                      </h6>
                    </label>
                    <v-text-field
                      v-model="LomName"
                      :rules="LomNameRules"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <h6>Title Clearance Name</h6>
                    <p></p>
                    <h3>{{ SelectedLoName }}</h3>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>

            <br /><br />

            <v-card elevation="3" outlined>
              <v-card-title>
                <span> Payment Details &nbsp; </span>
              </v-card-title>
              <v-container>
                <v-row wrap v-if="tableData1.length > 0">
                  <v-col align="center" cols="12" sm="12" md="12">
                    <!-- <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search records here"
                single-line
                hide-details
              ></v-text-field>-->
                    <br />
                    <v-data-table
                      class="elevation-1"
                      loading-text="Loading... Please wait"
                      disable-sort
                      disable-filtering
                      v-model="selected"
                      :search="search"
                      :loading="TableLoadingFlag"
                      :item-key="tableOptions1.ItemKey"
                      :single-select="tableOptions1.SingleSelectFlag"
                      :headers="tableColumns1"
                      :items="tableData1"
                      :items-per-page="tableOptions1.ItemsPerPage"
                      :footer-props="tableOptions1.FooterProps"
                      :hide-default-footer="tableOptions1.HideDefaultFooter"
                    >
                    </v-data-table>
                  </v-col>
                </v-row>
                <br />
                <v-row>
                  <v-col align="center" cols="12" sm="12" md="12">
                    <h3 class="text-primary">
                      Total Amount : Rs. {{ tableOptions1.TotalAmountTxt }}
                    </h3>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>

            <br />
            <v-row wrap>
              <v-col align="center" cols="12" sm="12" md="12">
                <upload-progress
                  :ProgessStart="ProgessStart"
                  :UploadPercentage="uploadPercentage"
                ></upload-progress>
                <v-btn
                  :disabled="!valid"
                  type="submit"
                  color="success"
                  class="mr-4"
                  @click.prevent="confirmAlert"
                  :loading="SubmitFlag"
                  >Pay now</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  data() {
    return {
      valid: true,
      LoadingFlag: false,
      SubmitFlag: false,
      ProgressLoadingFlag: false,
      StartupLoadingFlag: false,

      tableData1: [],
      tableColumns1: [],
      tableOptions1: [],
      selected: [],
      search: "",
      TableLoadingFlag: false,

      TotalAmount: 0,
      Member: {},

      MobileNoRules: [(v) => !!v || "Mobile No is required"],

      EmailIdRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],

      StateCodeRules: [(v) => !!v || "State is required"],
      StateCode: "",
      StateCodeOptions: [],
      StateCodeOptionsLoading: false,

      DistrictCodeRules: [(v) => !!v || "District is required"],
      DistrictCode: "",
      DistrictCodeOptions: [],
      DistrictCodeOptionsLoading: false,

      CityCodeRules: [(v) => !!v || "City is required"],
      CityCode: "",
      CityCodeOptions: [],
      CityCodeOptionsLoading: false,

      LomNameRules: [(v) => !!v || "New LO name is required"],
      LomName: "",
      SelectedLoName: "",

      ProgessStart: 0,
      uploadPercentage: 0,

      CurrentYearId: 0,

      ZoneCode: "",
      LomCode: "",
      YearCode: "",
      MonthCode: "",
      MonthName: "",

      LGBResolution: {},

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      JciYearCode: "",
      YearName: "",

      CartDetails: {},
      Order: {},
    };
  },
  mounted() {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;
        this.JciYearCode = tr.CurrentJciYearId;
        var YearName = tr.CurrentJciYearName;
        this.YearName = YearName;

        var MonthCode = this.$route.query.month;
        MonthCode = MonthCode == (null || undefined) ? 0 : MonthCode;
        console.log({ MonthCode });
        this.MonthCode = MonthCode;

        var tempDate1 = YearName + "-" + MonthCode + "-01";
        console.log({ tempDate1 });

        var tempDate2 = new Date(tempDate1);
        console.log({ tempDate2 });
        var MonthName = tempDate2.toLocaleString("en-us", { month: "long" });
        console.log({ MonthName });
        this.MonthName = MonthName;

        var ZoneCode = this.$session.get("ZoneId");
        ZoneCode = ZoneCode == (null || undefined) ? "" : ZoneCode;
        console.log("ZoneCode=" + ZoneCode);
        this.ZoneCode = ZoneCode;

        var LomCode = this.$session.get("LomId");
        LomCode = LomCode == (null || undefined) ? "" : LomCode;
        console.log("LomCode=" + LomCode);
        this.LomCode = LomCode;

        this.pageData();
      }
    },
    CartDetails: function () {
      console.log("watch CartDetails");
      this.LoadingFlag = false;
      this.TableLoadingFlag = false;
      var tr = this.CartDetails;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var tableOptions = tr.TableOptions;
        this.tableOptions1 = tableOptions;
        this.tableColumns1 = tr.TableHeader;
        this.tableData1 = tr.TableData;
        this.TotalAmount = tableOptions.TotalAmount;
      }
    },
    StateCodeOptions: function () {
      console.log("watch StateCodeOptions");
      this.LoadingFlag = false;
      this.StateCodeOptionsLoading = false;
    },
    DistrictCodeOptions: function () {
      console.log("watch DistrictCodeOptions");
      this.LoadingFlag = false;
      this.DistrictCodeOptionsLoading = false;
    },
    CityCodeOptions: function () {
      console.log("watch CityCodeOptions");
      this.LoadingFlag = false;
      this.CityCodeOptionsLoading = false;
    },
    LomName: function () {
      console.log("watch LomName");
      this.generateSelectedLoName();
    },
  },
  methods: {
    backPage() {
      this.$router.go(-1);
    },
    refreshPageData() {
      console.log("refreshPageData called");

      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "new_lo_title",
        Action: "create",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);

      this.TableLoadingFlag = true;
      var url2 = "api/new-lom/cart-details";
      var condition2 = {
        UserInterface: 1,
      };
      this.getPageDetails("CartDetails", {}, url2, condition2);
    },
    pageData() {
      console.log("pageData called");
      this.getStateCodeOptions();
      this.getMemberDetails();
    },
    validate() {
      if (this.$refs.form2.validate()) {
        this.snackbar = true;
      }
    },
    resetForm() {
      this.$refs.form2.reset();
      this.tableData1 = [];
    },
    resetValidation() {
      this.$refs.form2.resetValidation();
    },
    getStateCodeOptions() {
      console.log("getStateCodeOptions called");
      this.LoadingFlag = true;
      this.StateCodeOptionsLoading = true;
      var selectbox1_source = "StateCode";
      var selectbox1_destination = "StateCodeOptions";
      var selectbox1_url = "api/states/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getDistrictCodeOptions() {
      console.log("getDistrictCodeOptions called");
      var StateCode = this.StateCode;
      console.log({ StateCode });
      if (StateCode != "") {
        this.LoadingFlag = true;
        this.DistrictCodeOptionsLoading = true;
        var selectbox1_source = "DistrictCode";
        var selectbox1_destination = "DistrictCodeOptions";
        var selectbox1_url = "api/district/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          StatesCode: StateCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getCityCodeOptions() {
      console.log("getCityCodeOptions called");
      var DistrictCode = this.DistrictCode;
      console.log({ DistrictCode });
      if (DistrictCode != "") {
        this.CityCodeOptionsLoading = true;
        var selectbox1_source = "CityCode";
        var selectbox1_destination = "CityCodeOptions";
        var selectbox1_url = "api/city/options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          DistrictCode: DistrictCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getMemberDetails() {
      console.log("getMemberDetails is called");

      var MemberId = this.$session.get("MemberId");
      MemberId = MemberId == (null || undefined) ? "" : MemberId;

      console.log("MemberId=" + MemberId);

      if (MemberId != "") {
        this.StartupLoadingFlag = true;
        this.Member = {};

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/members/show";
        var upload = {
          UserInterface: 1,
          MemberId: MemberId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.StartupLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              var member = records[0];
              thisIns.Member = member;
              thisIns.MemberImageFlag = true;
              thisIns.Member.EmailId =
                member.EmailId == "NA" ? "" : member.EmailId;
              thisIns.Member.PrimaryMobile =
                member.PrimaryMobile == "NA" ? "" : member.PrimaryMobile;
              // thisIns.toast("success", output);
              // thisIns.resetForm();
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            this.StartupLoadingFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields. ";
        }
        this.toast("error", message);
      }
    },
    generateSelectedLoName() {
      console.log("generateSelectedLoName called");

      var CityCode = this.CityCode;
      var LomName = this.LomName;
      var SelectedLoName = "";

      if (CityCode != "") {
        var City = this.CityCodeOptions.find((data) => {
          return data.value == CityCode;
        });

        console.log("Selected City=" + JSON.stringify(City));

        SelectedLoName = "jci " + City.text + " " + LomName;
      } else {
        SelectedLoName = "jci {city name} " + LomName;
      }
      SelectedLoName = SelectedLoName.toUpperCase();
      console.log("SelectedLoName=" + SelectedLoName);

      this.SelectedLoName = SelectedLoName;
    },
    confirmAlert() {
      var validate1 = this.$refs.form2.validate();
      console.log({ validate1 });

      var TotalAmount = this.TotalAmount;
      console.log({ TotalAmount });

      var TotalAmountTxt = this.tableOptions1.TotalAmountTxt;
      console.log({ TotalAmountTxt });

      var Member = this.Member;
      console.log({ Member });

      var MemberId = Member.MemberId;
      console.log({ MemberId });

      // TotalAmount = 0;

      if (validate1 && TotalAmount > 0 && MemberId != "") {
        var htmlTxt = "";
        // htmlTxt += "<b>Please verify all the information before proceeding</b>";
        htmlTxt += "<b>Selected New LO details</b>";
        htmlTxt += "<br/><br/>";
        htmlTxt += "LO Name <b>" + this.SelectedLoName + "</b><br/>";
        htmlTxt += "Amount. <b>" + TotalAmountTxt + "</b><br/>";
        htmlTxt += "<br/><br/>";
        htmlTxt += "Do you want to continue?";
        Swal.fire({
          title: "Please verify all the information before proceeding",
          text: "",
          icon: "warning",
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: `Continue`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.submitForm();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields. ";
        }
        if (TotalAmount == 0) {
          message += "Payment details invalid. ";
        }
        if (MemberId == 0) {
          message += "President details invaid. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    submitForm() {
      console.log("submitForm is called");

      this.ProgressLoadingFlag = true;
      this.SubmitFlag = true;

      var Member = this.Member;
      console.log({ Member });

      var MemberId = Member.MemberId;
      console.log({ MemberId });

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/new-lom-title/order-create";

      let upload = new FormData();
      upload.append("UserInterface", 1);
      upload.append("LomCode", this.LomCode);
      upload.append("MobileNo", Member.PrimaryMobile);
      upload.append("EmailId", Member.EmailId);
      upload.append("NewLomName", this.SelectedLoName);
      upload.append("State", this.StateCode);
      upload.append("City", this.CityCode);
      upload.append("TotalAmount", this.TotalAmount);
      upload.append("LGBResolution", this.LGBResolution);

      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = {};
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          thisIns.ProgressLoadingFlag = false;
          thisIns.SubmitFlag = false;

          flag = response.data.flag;
          console.log({ flag });

          output = response.data.output;
          console.log({ output });

          records = response.data.records;
          console.log({ records });

          if (flag == 1) {
            thisIns.toast("success", output);
            thisIns.Order = records.Order;
            thisIns.makePayment();
          } else {
            thisIns.sweetAlert("error", output, false);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          thisIns.SubmitFlag = false;
          thisIns.ProgressLoadingFlag = false;
          console.log({ error });
          thisIns.toast("error", output);
        });
    },
    makePayment() {
      console.log("makePayment");
      // ev.preventDefault();
      var order = this.Order;
      const thisIns = this;
      var rzp1 = new Razorpay({
        key: order.rzp_key,
        account_id: order.rzp_account_id,
        order_id: order.rzp_order_id,
        amount: order.rzp_amount,
        name: order.rzp_name,
        currency: "INR",
        description: order.rzp_description,
        image: order.rzp_image,
        theme: {
          color: order.rzp_color,
        },
        prefill: {
          name: order.customer_name,
          email: order.customer_email_id,
          contact: order.customer_mobile_no,
        },
        notes: {
          address: "",
        },
        // callback_url: "http://domain.com/#/about/" + this.order_id,
        redirect: false,
        handler: function (response) {
          console.log("razorpay_payment_id=" + response.razorpay_payment_id);
          thisIns.ProgressLoadingFlag = true;
          thisIns.SubmitFlag = true;
          // thisIns.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
          thisIns.pageRedirect({}, "/order/success");
        },
      });
      rzp1.open();
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#new-lo-title-create {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 14px;
    border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody tr:nth-of-type(odd) {
    background-color: #f5f5f5;
  }
}
</style>